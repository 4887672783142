body {
    font-family: var(--main-font-family);
    background-color: var(--main-background-color);
    font-size: var(--text-size-medium);
}

td {
    padding: 0.5em;
}

input, select, button {
    margin:3px;
    border-radius: 5px;
    border-style: outset;
    /* border-color: blue; */
    border-width: 2px;
    font-size: var(--text-size-medium);
}

button:hover {
    cursor: pointer;
}

input[type=submit]:hover {
    cursor: pointer;
}

h4 {
    margin: 3px;
}

.modal {
    position: absolute;
    max-height: 90vh;
    max-width: 1100px;
    overflow-y: auto;
    top: 5%;
    left: 5%;
    right: 5%;
    padding: 2%;
    border-radius: 20px;
    background-color: var(--main-background-color);
    font-size: var(--text-size-medium);
}

.modaloverlay {
    background-color: var(--main-background-color);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

/* ************************* */
.apptopdiv {
    width: 100%;
    margin: auto;
}

.topimage {
    max-width: 150px;
    margin: auto;
    align-self: center;
}

/* ************************* */
.homepagetopdiv {
    width: 100%;
    max-width: 1024px;
    margin: auto;
}

/* .homepagetopdiv p, button {
    display: inline;
} */

.homepageheaderdiv {
    /* position: relative; */
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.hamburgerdiv {
    width: 100%;
    display: flex;
    position: absolute;
    top: 0;
}
.hamburgerdiv :hover {
    cursor: pointer;
}

.hamburgerdiv img {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
}

.hamburgerelementsdiv {
    position: absolute;
    top: 3em;
    right: 0.5em;
}

.hamburgerelementsdiv p, button {
    text-align: right;
    margin-left: auto;
    margin-right: 0px !important;
}

/* ************************* */
.memberformtopdiv {
    width: 100%;
    max-width: 600px;
    margin: auto;
    text-align: center;
}

.memberform {
    margin-left: auto;
    margin-right: auto;
}

.memberform input, select, button {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.memberform input[type="radio"] {
    display:inline;
}

.subscriptionactivediv {
    color:green;
    text-align: center;
}

.subscriptionnotactivediv {
    color:red;
    text-align: center;
}


/* ************************* */
.memberformadmintoppdiv {
    width: 100%;
    margin: auto;
    text-align: center;
}

/* ************************* */
.memberadminform {
    margin-left: auto;
    margin-right: auto;
}

.memberadminform input, button {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.memberadminform select {
    display: inline;
}

.memberlisttable {
    margin-left: auto;
    margin-right: auto;
    border-spacing: 20px 1px;
    text-align: left;
    overflow-x: auto;
    white-space: nowrap;
}

.memberlisttable td {
    padding: 1px;
}

.memberlisttable tr :hover {
    cursor: pointer;
    background-color: var(--modal-background-color);
}

.emailsendingtopdiv {
    margin: auto;
}

.emailsendingtopdiv input, button, textarea label {
    /* display: block; */
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}

.filepickerbtn {
    margin-left: 0;
}

.sendemailbtn {
    margin-right: 0;
}

.deletebtn {
    display:inline;
    background-color: red;
}

.centerbtn {
    margin: 0 auto !important;
    display: block;
}

.vippsbtn {
    margin: 0 auto !important;
    display: flex;
    /* width: 250px; */
    height: 35px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex-shrink: 0;
    border-radius: 5px;
    border: none;
    background: var(--orange-50, #FF5B24);
    color: #FFFFFF;
}

.donationdiv {
    text-align: center;
}

.vippsqrimg {
    max-width: 150px;
}

.homepagebottomdiv {
    margin-top: 50px;
    text-align: center;
    margin-bottom: 10px;
}